import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import xbutton from "../../Assets/Images/xbutton.png";
import "../Styles/SignInUpPopUp.scss";

function PopUp(props) {
  // const [showPopUp, setShowPopUp] = useState(props.state);
  // const togglePopUp = () => setShowPopUp(!showPopUp);

  // const handleClick = () => {
  //   props.toggleState();
  //   setShowPopUp(!showPopUp);
  // };
  const history = useHistory();

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div>
      <div className="popup-overlay">
        <div
          className="popUp-container"
          ref={popupRef}
          onClick={props.onClose}
          style={{ zIndex: 999 }}
        >
          <div className="popUp" onClick={(e) => e.stopPropagation()}>
            <div className="exitPopup">
              <img
                src={xbutton}
                onClick={props.onClose}
                className="image-175"
              />
            </div>
            <h2 className="popup-title">Like what you See?</h2>
            <p className="popup-p">
              Sign in to Like, and Save Gifts As well as Keep track of your
              loved ones dates
            </p>
            <div className="sign-div">
              <button
                className="sign-btn"
                onClick={() => history.push("/login")}
              >
                Login
              </button>
              <button
                className="sign-btn"
                onClick={() => history.push("/login#signup-form")}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopUp;
