import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { TiVendorMicrosoft } from "react-icons/ti";
import { register } from "../../Services/api.jsx";
import {
  validateEmail,
  validatePassword,
  validateConfirmPassword,
} from "../../Utils/Validation.jsx";
import GoogleSignIn from "./GoogleSignIn.jsx";

const SignUp = ({ setIsSignIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const history = useHistory();

  const handleSignup = async (e) => {
    e.preventDefault();
    const emailValidationError = validateEmail(email);
    const passwordValidationError = validatePassword(password);
    const confirmPasswordValidationError = validateConfirmPassword(
      password,
      confirmPassword
    );

    if (
      emailValidationError ||
      passwordValidationError ||
      confirmPasswordValidationError
    ) {
      setEmailError(emailValidationError);
      setPasswordError(passwordValidationError);
      setConfirmPasswordError(confirmPasswordValidationError);
      setErrorMessage("Please fix the errors above.");
    } else {
      setEmailError("");
      setPasswordError("");
      setConfirmPasswordError("");
      setErrorMessage("");
      try {
        const response = await register(email, password);
        if (response.status === 409) {
          setErrorMessage(
            "Email already exists. Please use a different email."
          );
        } else {
          const token = await response.json();
          window.localStorage.setItem("token", token.token);
          window.localStorage.setItem("email", email);
          localStorage.setItem("loggedin", JSON.stringify(true));
          history.push("/search");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="w-full max-w-md space-y-8">
      <div>
        <h2 className="mt-6 text-center text-3xl font-bold text-neutral-green-900">
          Create your account
        </h2>
        <p className="mt-2 text-center text-sm text-neutral-green-600">
          Sign up with Google
        </p>
      </div>
      <div className="space-y-4">
        <GoogleSignIn />
        {/* <button className="w-full flex items-center justify-center px-4 py-2 border border-neutral-green-300 text-neutral-green-700 hover:bg-grey-600">
          <TiVendorMicrosoft className="mr-2 h-5 w-5" />
          Sign up with Microsoft
        </button> */}
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-neutral-green-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="bg-grey-100 px-2 text-neutral-green-600">
            Or continue with
          </span>
        </div>
      </div>
      <div className="space-y-6">
        <form onSubmit={handleSignup}>
          <div className="space-y-4">
            <div className="space-y-2">
              <label
                htmlFor="email"
                className=" text-sm font-medium text-neutral-green-700"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                placeholder="m@example.com"
                required
                className="w-full px-3 py-2 border border-neutral-green-300 rounded-md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <p className="text-red-500 text-sm">{emailError}</p>
              )}
            </div>
            <div className="space-y-2">
              <label
                htmlFor="password"
                className=" text-sm font-medium text-neutral-green-700"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                placeholder="Password"
                required
                className="w-full px-3 py-2 border border-neutral-green-300 rounded-md"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && (
                <p className="text-red-500 text-sm">{passwordError}</p>
              )}
            </div>
            <div className="space-y-2">
              <label
                htmlFor="confirmPassword"
                className=" text-sm font-medium text-neutral-green-700"
              >
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                required
                className="w-full px-3 py-2 border border-neutral-green-300 rounded-md"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {confirmPasswordError && (
                <p className="text-red-500 text-sm">{confirmPasswordError}</p>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between pt-6">
            {/* <div className="flex items-center">
                <input
                    id="remember"
                    type="checkbox"
                    className="h-4 w-4 text-red-600 border-neutral-green-300 rounded"
                />
                <label
                    htmlFor="remember"
                    className="ml-2 text-sm text-neutral-green-900"
                >
                    Remember me
                </label>
                </div> */}
            {/* <div className="text-sm">
              <a href="#" className="font-medium text-red-600 hover:underline">
                Forgot your password?
              </a>
            </div> */}
          </div>
          <button
            type="submit"
            className="mt-4 w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
          >
            Sign up
          </button>
          {errorMessage && (
            <p className="text-red-500 text-sm pt-2">{errorMessage}</p>
          )}

          <p className="text-sm text-neutral-green-700 mt-4 text-center">
            By clicking Sign up, Continue with Facebook, Continue with Google or
            Continue with Apple, you agree to our{" "}
            <a href="/terms" className="text-red-500 underline">
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a href="/privacy" className="text-red-500 underline">
              Privacy Statements
            </a>
            .
          </p>
        </form>
      </div>
      <div className="flex items-center justify-center">
        <div className="text-sm">
          Already have an account?{" "}
          <a
            href="#"
            className="font-medium text-red-600 hover:underline"
            onClick={(e) => {
              e.preventDefault();
              setIsSignIn(true);
            }}
          >
            Sign in
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
