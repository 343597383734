import { React, useState } from "react";
import Navbar from "../NavBar/NavBar.jsx";
import Footer from "../Footer/Footer.jsx";
import { Grid, Paper, Switch, TextField } from "@material-ui/core";
import classes from "../Styles/IntegrateWithUs.scss";
import giftimg from "../../Assets/Images/image-1-3.png";

const IntegrateWithUs = () => {
  const [website, setWebsite] = useState("");
  const [email, setemail] = useState("");
  const [sent, setsent] = useState(false);
  const [buttonText, setbuttonText] = useState("Communicate");
  const [emailError, setemailError] = useState(false);

  setTimeout(() => {
    setsent(false);
  }, 3000);

  const handleChangeEmail = async (e) => {
    if (
      !e.target.value.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setemail(e.target.value);
      setemailError(true);

      if (email === "") {
        // check if the input is empty
        setemailError(true);
      }
    } else {
      setemail(e.target.value);
      setemailError(false);
    }
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    setbuttonText("Sending...");
    const params = {
      email: email,
    };
    await fetch(
      "https://gzd1kcywwk.execute-api.us-east-1.amazonaws.com/api/sendIntegration",
      {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((error) => {
      console.log("found error", error);
    });
    setbuttonText("Communicate");
  };

  return (
    <div>
      <div>
        {/* <NavBar></NavBar> */}
      </div>
      <div className="body">
        <div className="integrate-div mainDiv">
          <div className="gatherIntegrate">
            <h3 className="integrate">
              {" "}
              Do you want to increase your revenue and your online visability?
              You can integrate with our platform. Through one API.
            </h3>
            <p>
              Combine your creative product with our emphatic capabilities and
              NLP technologies. Add your product or service to the platform and
              help us create a loving world.
            </p>
            <p>
              {" "}
              Discuss your product/service with one of our representatives
              virtually meeting in 30 Mins. and grant access to our API.
            </p>
          </div>
          <Grid>
            <form className="contact-form" onSubmit={(e) => formSubmit(e)}>
              <TextField
                id="standard-multiline-flexible"
                placeholder="Enter Your WebSite"
                multiline
                rowsMax={4}
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                required
                type="text"
              />
              <br />
              <br />
              <br />
              <TextField
                id="outlined-basic"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => handleChangeEmail(e)}
                error={emailError}
                required
                type="email"
              />
              <br />
              <br />
              <br />

              <div className="button-74">
                <button type="submit" className="button button-primary">
                  {buttonText}
                </button>
              </div>
            </form>
          </Grid>
        </div>

        <div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
};

export default IntegrateWithUs;
