import { useHistory } from "react-router-dom";
import "../Styles/MiniNav.scss";

export default function MiniNav() {
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("token");
    history.push("/");
    window.localStorage.setItem("loggedin", false);
  };

  return (
    <header className="block">
      <ul className="header-menu horizontal-list navbar-list ">
        <li className="navbar-item">
          <a className="header-menu-tab" href="#2">
            <span className="icon fontawesome-user scnd-font-color " />
            Account
          </a>
        </li>
        {/* <li className="navbar-item">
          <a className="header-menu-tab" href="#1">
            <span className="icon entypo-cog scnd-font-color" />
            Settings
          </a>
        </li> */}
        {/* <li className="navbar-item">
          <a className="header-menu-tab" href="#5">
            <span className="icon fontawesome-star-empty scnd-font-color" />
            Favorites
          </a>
        </li> */}
      </ul>
      <div className="profile-menu flex items-center	">
        <div
          class="hover:grow text-custom-sweetBrown text-center text-normal font-bold py-2 px-4 rounded-full inline-block border-2 border-custom-bittersweetShimmer hover:-translate-1 hover:scale-110 cursor-pointer"
          onClick={logout}
        >
          Logout
        </div>
        {/* <div className="profile-picture small-profile-picture">
          <img
            width="40px"
            alt="Anne Hathaway picture"
            src="https://img.freepik.com/premium-vector/female-robot-avatar-facing-half-side-with-long-shadow_100659-286.jpg?w=2000"
          />
        </div> */}
      </div>
    </header>
  );
}
