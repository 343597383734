import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import logo from "../../Assets/Images/Ciqlercompined2024.png";
import useAppStore from "../../Store/Store.jsx"; // Import useAppStore from the store

import dropdown from "../../Assets/Images/dropdown.png";
import "../Styles/NavBar.scss";
import giftsbotfloat from "../../Assets/Images/Ciqler small.png";
import DropdownMenu from "./dropdown.jsx";

const Navbar = () => {
  const history = useHistory();
  const handleClick = () => {
    // console.log("Image clicked!");
    setSearchText(""); // Set searchText to empty string when image is clicked
    setTag("");
  };

  const { searchText, setSearchText, tag, setTag } = useAppStore(); // Destructure searchText and setSearchText from useAppStore

  const [loggedIn, setLoggedin] = useState(false);

  useEffect(() => {
    const handleLoggedin = () => {
      const loggedIn = JSON.parse(localStorage.getItem("loggedin"));
      if (loggedIn) {
        setLoggedin(true);
      }
    };
    handleLoggedin();
  }, []);

  return (
    <div className="shadow-sm">
      <div className="flex gap-5 justify-between px-8 py-6 backdrop-blur-[20px] bg-stone-50 w-full]">
        <img
          className="max-w-full aspect-[4.76] w-[130px] hover:cursor-pointer"
          src={logo}
          alt="Logo"
          onClick={() => {
            history.push("/");
            handleClick(); // Call handleClick function when image is clicked
          }}
        />
        <DropdownMenu loggedIn={loggedIn} />
        <nav className="bigScreen">
          <ul>
            <li>
              <a href="/about"> About </a>
            </li>
            <li>
              <a href="/Integratewithus"> Join Us </a>
            </li>

            <li className="go-premium-cta">
              <a href="/search" className="white">
                Try It
              </a>
            </li>

            {!loggedIn && (
              <li className="go-premium-cta bg-white ">
                <a href="/login" className="">
                  <span className=" text-custom-sweetBrown">Login/</span>
                  <span className=" text-custom-newYorkPink">SignUp</span>
                </a>
              </li>
            )}
            {loggedIn && (
              <div className="profile-picture small-profile-picture">
                <a href="/profile">
                  <img
                    width="40px"
                    src="https://img.freepik.com/premium-vector/female-robot-avatar-facing-half-side-with-long-shadow_100659-286.jpg?w=2000"
                  />
                </a>
              </div>
            )}
            {loggedIn !== true && (
              <li className="go-login-cta">
                <a href="/login">Login</a>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
