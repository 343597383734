import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../Styles/ProfileComponent.scss";
import ProductsMini from "../Product/ProductsMini.jsx";

export default function ProfileComponent(props) {
  const [activeComponent, setActiveComponent] = useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    const message = event.target.innerText;

    localStorage.setItem("ClickedSearch", message);
    window.open("/search", "_blank");
  };

  const handleComponentClick = (componentName) => {
    if (activeComponent === componentName) {
      setActiveComponent(null); // Hide the currently active component if it's clicked again
    } else {
      setActiveComponent(componentName); // Show the clicked component
    }
  };

  return (
    <div className=" flex flex-col profile">
      <div className=" block">
        <a className="add-button" href="#28">
          <span className="icon entypo-plus scnd-font-color" />
        </a>
        <div className="profile-picture big-profile-picture clear">
          <img
            width="150px"
            alt="Anne Hathaway picture"
            src="https://img.freepik.com/premium-vector/female-robot-avatar-facing-half-side-with-long-shadow_100659-286.jpg?w=2000"
          />
        </div>
        <h1 className="user-name">{props.response.email}</h1>
        <div className="profile-description"></div>
        <ul className="profile-options horizontal-list">
          <li className="profile-option">
            <a
              className="comments"
              onClick={() => handleComponentClick("Clicks")}
            >
              <p>
                <span className="icon fontawesome-eye-open scnd-font-color" />

                {props.response.clicks && props.response.clicks.length}
              </p>
            </a>
          </li>
          <p />
          <li className="profile-option">
            <a
              className="views"
              onClick={() => handleComponentClick("Searches")}
            >
              <p>
                <span className="icon fontawesome-search scnd-font-color" />
                {props.response.searches && props.response.searches.length}
              </p>
            </a>
          </li>
          <p />
          <li className="profile-option">
            <a
              className="likes"
              onClick={() => handleComponentClick("Favorites")}
            >
              <p>
                <span className="icon fontawesome-heart-empty scnd-font-color" />
                {props.response.favorites && props.response.favorites.length}
              </p>
            </a>
          </li>
          <p />
        </ul>
      </div>
      {activeComponent === "Clicks" && <Clicks />}
      {activeComponent === "Clicks" && (
        <div className=" block min-h-0">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              overflowY: "scroll",
            }}
          >
            {props.response.clicks &&
              props.response.clicks.map((gift) => (
                <div>
                  <ProductsMini
                    key={gift._id}
                    id={gift._id}
                    title={gift.title}
                    image={gift.img}
                    url={gift.link}
                    price={gift.price}
                    country={gift.country}
                    favorite="null"
                  ></ProductsMini>
                </div>
              ))}
          </div>
        </div>
      )}

      {activeComponent === "Searches" && <Searches />}
      {activeComponent === "Searches" && (
        <div className=" block">
          <div className="flex flex-wrap content-center justify-center m-4">
            {props.response.searches &&
              props.response.searches.map((item, index) => (
                <a className="cursor-pointer contents" onClick={handleClick}>
                  <p
                    className="txt-area h-max w-max m-2 p-3 text-red-800 font-normal sm:text-sm		 "
                    key={index}
                  >
                    {item}
                  </p>
                </a>
              ))}{" "}
          </div>
        </div>
      )}

      {activeComponent === "Favorites" && <Favorites />}
      {activeComponent === "Favorites" && (
        <div className=" block min-h-0">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              overflowY: "scroll",
            }}
          >
            {props.response.favorites &&
              props.response.favorites.map((gift) => (
                <div className=" scale-90 ">
                  <ProductsMini
                    key={gift._id}
                    id={gift._id}
                    title={gift.title}
                    image={gift.img}
                    url={gift.link}
                    price={gift.price}
                    country={gift.country}
                    favorite="null"
                  ></ProductsMini>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

function Clicks() {
  return <div className="text-amber-600	">Clicks:</div>;
}

function Searches() {
  return <div className="text-teal-500	"> Searches: </div>;
}

function Favorites() {
  return <div className="text-red-700		">Favorites:</div>;
}
