import React, { useState } from "react";
import "../Styles/FilterDropDown.scss";

function DropdownButton(props) {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClick = (action) => {
    toggleMenu();
    action();
  };

  return (
    <div className="mt-5">
      <button
        className="justify-center items-center px-16 py-2 mt-1 text-center text-red-400 whitespace-nowrap bg-grey-500 border-red-400 border-2 leading-[150%] rounded-[100px] shadow-sm"
        onClick={toggleMenu}
      >
        <b> {props.buttonLabel} </b>
      </button>
      {showMenu && (
        <div className="block mt-2 bg-grey-500 text-left p-3 rounded-2xl border-red-400 border-2">
          {props.options.map((option) => (
            <div
              key={option.id}
              className="hover:bg-green-100 hover:rounded-md bg-grey-500 border-black py-1 px-1 "
              onClick={() => handleClick(option.action)}
            >
              <b> {option.label} </b>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default DropdownButton;
