import { React, useState, useEffect, useRef } from "react";
import Profile from "./Profile.jsx";
import Navbar from "../NavBar/NavBar.jsx";
// import SignInUp from "./Components/SignInUpPopUp.jsx";

const CalenderPage = () => {
  return (
    <div>
      <Navbar />
      <Profile />
      <Profile />
      {/* <SignInUp /> */}
    </div>
  );
};

export default CalenderPage;
