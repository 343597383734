import { useState } from "react";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../Assets/Images/Ciqlercompined2024.png";
import useAppStore from "../../Store/Store.jsx";
import { CgProfile } from "react-icons/cg";

export default function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [accountDropdown, setAccountDropdown] = useState(false);

  const history = useHistory();
  const handleClick = () => {
    // console.log("Image clicked!");
    setSearchText(""); // Set searchText to empty string when image is clicked
    setTag("");
  };

  const { searchText, setSearchText, tag, setTag } = useAppStore(); // Destructure searchText and setSearchText from useAppStore

  useEffect(() => {
    const handleLoggedin = () => {
      const isLoggedIn = JSON.parse(localStorage.getItem("loggedin"));
      if (isLoggedIn) {
        setIsLoggedIn(true);
      }
    };
    handleLoggedin();
  }, []);

  return (
    <header className="flex h-20 w-full items-center px-4 md:px-6 bg-grey-100">
      <Link to="/" className="flex items-center">
        {/* Replace with your logo or icon */}
        <img
          className="max-w-full aspect-[4.76] w-[130px] hover:cursor-pointer"
          src={logo}
          alt="Logo"
          onClick={() => {
            handleClick(); // Call handleClick function when image is clicked
          }}
        />{" "}
      </Link>
      <div className="flex-1 hidden justify-center lg:flex">
        {/* Your navigation menu items for larger screens */}
        {/* <nav className="flex space-x-4">
          <Link href="#">About</Link>
          <Link href="#">Services</Link>
          <Link href="#">Contact</Link>
        </nav> */}
      </div>
      <div className="ml-auto flex gap-2">
        {isLoggedIn && (
          // User dropdown menu for logged-in users
          <div className="relative">
            <button
              onClick={() => setAccountDropdown(!accountDropdown)}
              className={`text-gray-600 hover:text-gray-900 focus:outline-none ${
                isLoggedIn ? "" : "hidden"
              }`}
            >
              <CgProfile size={30} />
            </button>
            {/* Dropdown content */}
            {accountDropdown && (
              <div className="absolute right-0 mt-2 w-48 bg-grey-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-10">
                {/* <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  My Account
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Settings
                </a> */}
                <button
                  onClick={() => {
                    setIsLoggedIn(false);
                    localStorage.removeItem("token");
                    localStorage.removeItem("loggedin");
                  }}
                  className="block w-full px-4 py-2 text-sm text-grey-900 hover:bg-gray-200 text-left rounded-lg"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        )}
        {!isLoggedIn && (
          // Login button for not logged-in users
          <button
            onClick={() => {
              history.push("/login");
            }}
            className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-grey-100 bg-red-300 hover:bg-red-400"
          >
            Login{" "}
          </button>
        )}
        {/* Hamburger menu for small screens */}
        {/* <button onClick={() => setIsNavOpen(!isNavOpen)} className="lg:hidden">
          <svg
            className="h-6 w-6 text-gray-600 hover:text-gray-900 focus:outline-none"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button> */}
      </div>
      {/* Mobile navigation menu */}
      {isNavOpen && (
        <div className="lg:hidden absolute top-0 left-0 h-screen w-64 bg-grey-100 shadow-lg">
          <nav className="pt-8 px-4">
            <ul className="space-y-4">
              <li>
                <Link href="#">About</Link>
              </li>
              <li>
                <Link href="#">Services</Link>
              </li>
              <li>
                <Link href="#">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
}
