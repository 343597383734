import axios from "axios";
import { BASE_URL } from "../Global";

// BASE_URL="http://localhost:5002"

//Login
export const login = async (email, password) => {
  const response = await fetch(`${BASE_URL}/api/user/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  return response;
};

//SignUp
export const register = async (email, password) => {
  const response = await fetch(`${BASE_URL}/api/user/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  return response;
};

//Forget Password
export const forgetPassword = async (email) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/user/forget-password`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

//Reset Password
export const resetPassword = async (password, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/user/reset-password`,
      { password },
      { params: { token } }
    );
    return { success: true, message: response.data.message };
  } catch (error) {
    return { success: false, message: error.response.data.error };
  }
};

// //Forget Password
// try {
//   const response = await axios.post(
//     `${BASE_URL}/api/user/reset-password`,
//     { password },
//     { params: { token } }
//   );
//   setMessage(response.data.message);
//   setPassword("");
//   setConfirmPassword("");
//   setTimeout(() => {
//     window.location.href = "/login";
//   }, 3000);
// } catch (error) {
//   setMessage(error.response.data.error);
// }
