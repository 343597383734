import { React, useEffect, useRef, useReducer } from "react";
import "../Styles/Search.scss";
import { useHistory, useLocation } from "react-router-dom"; // Import useHistory and useLocation

import useAppStore from "../../Store/Store";

const initialState = {
  isRecognizing: false,
  buttonIcon: (
    <svg
      width="16"
      height="20"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 8.25C12.25 11.1495 9.8995 13.5 7 13.5M7 13.5C4.10051 13.5 1.75 11.1495 1.75 8.25M7 13.5V16.5M7 16.5H4M7 16.5H10M7 10.5C5.75736 10.5 4.75 9.49264 4.75 8.25V3.75C4.75 2.50736 5.75736 1.5 7 1.5C8.24264 1.5 9.25 2.50736 9.25 3.75V8.25C9.25 9.49264 8.24264 10.5 7 10.5Z"
        stroke="#BD4533"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
};

function reducer(state, action) {
  switch (action.type) {
    case "START_RECOGNITION":
      return {
        ...state,
        isRecognizing: true,
        buttonIcon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="20"
            viewBox="0 0 14 18"
            fill="none"
          >
            <path
              d="M12.25 8.25C12.25 11.1495 9.8995 13.5 7 13.5M7 13.5C4.10051 13.5 1.75 11.1495 1.75 8.25M7 13.5V16.5M7 16.5H4M7 16.5H10M7 10.5C5.75736 10.5 4.75 9.49264 4.75 8.25V3.75C4.75 2.50736 5.75736 1.5 7 1.5C8.24264 1.5 9.25 2.50736 9.25 3.75V8.25C9.25 9.49264 8.24264 10.5 7 10.5Z"
              stroke="#088F91"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
      };
    case "STOP_RECOGNITION":
      return {
        ...state,
        isRecognizing: false,
        buttonIcon: initialState.buttonIcon,
      };
    default:
      return state;
  }
}

const RecordButton = (pageType) => {
  const {
    searchText,
    setSearchText,
    handleSearch,
    getWantedGifts,
    isLoading,
    error,
    search,
  } = useAppStore();
  const recognitionRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory(); // Initialize useHistory

  const handleSearchButtonClick = async (currentSearchText) => {
    await getWantedGifts(currentSearchText); // Fetch gifts based on currentSearchText
    await handleSearch(currentSearchText); // Perform search operation with currentSearchText

    const encodedSearchText = encodeURIComponent(currentSearchText);
    history.push(`/search?query=${encodedSearchText}`); // Redirect to search page with query params
  };

  const startRecognition = () => {
    if (!state.isRecognizing) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition) {
        console.error(
          "Speech Recognition API is not supported in this browser."
        );
        return;
      }
      const newRecognition = new SpeechRecognition();
      newRecognition.lang = "en-US";
      newRecognition.onstart = () => {
        dispatch({ type: "START_RECOGNITION" });
        // setSearchText("Listening ... "); // Set searchText to "Listening ..." when recognition starts
      };

      newRecognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        console.log("Transcript:", transcript);
        setSearchText(transcript);
        handleSearchButtonClick(transcript);
      };

      newRecognition.onend = () => {
        dispatch({ type: "STOP_RECOGNITION" });
      };

      recognitionRef.current = newRecognition;
      recognitionRef.current.start();
    }
  };

  return (
    <div>
      <div
        className="relative group flex justify-center items-center px-1 w-10 h-10 bg-orange-50 rounded-3xl aspect-square border-1 border-red-500"
        onClick={() => {
          startRecognition();
        }}
      >
        {state.buttonIcon}
        <div
          id="Recording-tooltip"
          className="absolute bg-green-600 text-white py-1 px-3 rounded mb-1.5 bottom-full text-center font-sans"
          style={{ display: state.isRecognizing ? "block" : "none" }} // Tooltip visibility controlled by isRecognizing state
        >
          <span className="text-sm font-semibold">
            {state.isRecognizing ? "Listening..." : "Voice Search"}
          </span>
        </div>
      </div>
    </div>
  );
};
export default RecordButton;
