import { React, useState, useEffect, useRef } from "react";
import "../Styles/Home.scss";
import giftimg from "../../Assets/Images/image-1-3.png";
import giftboximg from "../../Assets/Images/image-10-3.png";
import gifthandimg from "../../Assets/Images/gift-1-1.png";
import { blue } from "@material-ui/core/colors";
import videoFile from "../../Assets/Images/CiqlerVid.mp4";
// import giftHolder from "../../Assets/Images/biggifts.png";
// import giftGirl from "../../Assets/Images/giftGirlpinkFlower.png";
import giftGirl from "../../Assets/Images/biggifts.png";
// import giftGirl from "../../Assets/Images/GirlGiftHDDD.png";
// import giftGirl from "../../Assets/Images/girlwithgift.png";
// import giftHolder from "../../Assets/Images/gift_woman.png";
import bigglasses from "../../Assets/Images/guywithbigglass.png";
import Search from "../SearchArea/Search.jsx";
import LikeButton from "../Product/Hearts.jsx";
import useAppStore from "../../Store/Store.jsx";
import { useHistory, useLocation } from "react-router-dom"; // Import useHistory and useLocation

const Body = () => {
  const { setSearchText, getWantedGifts, handleSearch } = useAppStore();
  const middleSection = [
    {
      title: "Describe",
      markTitle: "Search",

      bgColor: "bg-[#E57B34]",
      textColor: "text-stone-400",
      description:
        "Describe the person/event you’re trying to get a gift for. The more details the better the gift",
      image: bigglasses,
      searchComponent: <Search />,
    },
    {
      title: "Click",
      markTitle: "Fast Pill",

      bgColor: "bg-red-400",
      textColor: "text-stone-50",
      description:
        "Get a Head-start with the fast pills. One click away from a whole Genre",
      categoryDisplay: true,
    },
    {
      title: "Buy",
      markTitle: "Perfect Gifts",
      bgColor: "bg-green-800",
      textColor: "text-stone-50",
      description: "Every gift is tailored for your special person.",
      buyComponent: true,
    },
  ];
  const categories = [
    {
      name: "Fashion",
      description:
        " <span data-type='mention' class='mention' data-id='1' data-label='They find comfort and self-expression through fashion'>fashion</span>, showcasing their unique style.",
      bgColor: "bg-yellow-900",
      bgColor2: "bg-red-900",
    },
    {
      name: "Gourmet & Wine",
      description:
        "<span data-type='mention' class='mention' data-id='1' data-label='They love trying new gourmet food and wine'>gourmet</span>, enjoying each sip and bite as a special treat.",
      bgColor: "bg-red-900",
      bgColor2: "bg-red-700",
    },
    {
      name: "Romantic",
      description:
        "<span data-type='mention' class='mention' data-id='1' data-label='They are captivated by love'>romantic</span>, creating moments of affection and cherished memories.",
      bgColor: "bg-red-700",
      bgColor2: "bg-red-500",
    },
    {
      name: "Decor",
      description:
        "<span data-type='mention' class='mention' data-id='1' data-label='They love decor'>decor</span>, creatively transforming ordinary spaces into beautiful and inspiring havens.",
      bgColor: "bg-teal-800",
      bgColor2: "bg-green-900",
    },
    {
      name: "Jewelry",
      description:
        "<span data-type='mention' class='mention' data-id='1' data-label='They wear jewels that add elegance and grace to every outfit'>jewels</span>, expressing their unique style with shimmering beauty.",
      bgColor: "bg-teal-600",
      bgColor2: "bg-green-700",
    },
    {
      name: "Artistic",
      description:
        "<span data-type='mention' class='mention' data-id='1' data-label='They are deeply involved in creativity'>ART</span>, using their skills to create art that captures hearts and minds.",
      bgColor: "bg-emerald-300",
      bgColor2: "bg-green-500",
    },
    {
      name: "Technology",
      description:
        "<span data-type='mention' class='mention' data-id='1' data-label='They love technology'>Technology</span>, diving into the digital world with excitement, where every step forward means progress.",
      bgColor: "bg-orange-400",
      bgColor2: "bg-orange-500",
    },
    {
      name: "Unique",
      description:
        "<span data-type='mention' class='mention' data-id='1' data-label='They embrace individuality'>Unique</span>, celebrating uniqueness in every aspect, a reflection of their vibrant spirit and limitless creativity.",
      bgColor: "bg-orange-300",
      bgColor2: "bg-orange-300",
    },
    {
      name: "Vintage",
      description:
        "<span data-type='mention' class='mention' data-id='1' data-label='They love the charm of vintage things'>Vintage</span>, adding nostalgia and character to every moment, cherishing the stories behind each find.",
      bgColor: "bg-red-300",
      bgColor2: "bg-red-100",
    },
  ];

  const history = useHistory(); // Initialize useHistory

  const handleAction = (sentence) => {
    // Navigate to the search page
    const encodedSearchText = encodeURIComponent(sentence);
    history.push(`/search?query=${encodedSearchText}`);
  };

  const handleRedirect = () => {
    window.location.href =
      "https://www.uncommongoods.com/product/lovebox-spinning-heart-messenger";
  };

  return (
    // <div className="overlap-group4">
    // <img className="image-1" src={image1} alt="image 1" />

    <div>
      <div className="relative">
        <div className="flex flex-col items-center px-40 py-36 gap-3 bg-red-400 rounded-t-none rounded-b-[20px] bg-blend-darken max-md:px-4">
          <img
            src={giftGirl}
            alt="Image"
            // className="absolute top-0 left-[-230px] h-full w-auto object-fill z-0 md:object-cover hidden sm:block"
            // className="absolute top-[1px] left-[-330px] h-full w-auto object-fill z-0 md:object-cover md:left-[-200px] hidden sm:block"
            className="absolute top-0 right-0 h-full w-auto object-fill z-0 md:object-cover hidden sm:block"
          />

          <div className=" max-w-[1100px] z-1">
            <div className=" font-open-sans mb-8 w-full text-6xl font-bold tracking-wide text-center leading-[72px] text-grey-500 max-md:mt-10 max-md:max-w-full max-md:text-4.5xl max-md:leading-[60px]">
              Describe them, and discover the{" "}
              <span className="text-red-800">perfect</span> gift
            </div>
          </div>
          <div className="flex justify-center flex-wrap w-full z-1">
            {/* <Loader /> */}
            <Search pageType="home" />
            <div className="flex gap-3 justify-center lg:flex-nowrap md:flex-wrap px-20 mt-12 w-full text-base font-bold leading-6 text-center whitespace-nowrap text-stone-50 max-md:flex-wrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
              {categories.map((category) => (
                <div
                  className={`hover:bg-grey-500 hover:text-red-500 border-2 border-red-400 hover:border-red-500 hover:cursor-pointer justify-center px-3 py-1.5 shadow ${category.bgColor} bg-opacity-40 rounded-[100px]`}
                  onClick={() => handleAction(category.description)}
                >
                  {category.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-20">
        <div className="w-full text-6xl font-bold tracking-wide text-center leading-[72px] text-stone-800 max-md:max-w-full max-md:text-4xl">
          How to Ciqler:
        </div>
        <div className="px-5 mt-12 w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-lg:flex-col max-lg:gap-0 max-md:">
            {middleSection.map((item, index) => (
              <div className="flex flex-col ml-5 w-[33%] max-lg:ml-0 max-lg:w-full h-auto">
                <div className="flex flex-col h-full py-3.5 pt-2.5 pb-12 font-bold max-md:mt-6">
                  <div className="self-center text-4xl leading-10 text-center text-stone-800">
                    {item.title}
                  </div>
                  <div
                    className={`flex flex-col justify-center py-px mt-6 text-base leading-6 text-center whitespace-nowrap h-full ${item.bgColor} ${item.textColor} rounded-[20px] items-center`}
                  >
                    {/* Conditional rendering for the Describe section */}
                    {item.image && (
                      <div className="flex overflow-hidden relative flex-col h-full justify-center px-2.5 pb-11 w-full aspect-[0.78]">
                        <img
                          loading="lazy"
                          src={item.image}
                          className="absolute inset-0 w-full h-full object-cover"
                        />
                        {item.searchComponent}
                      </div>
                    )}
                    {/* Conditional rendering for the Click section */}
                    {item.categoryDisplay && (
                      <div className="flex gap-3 justify-center md:flex-wrap px-20 my-16 w-full text-base font-bold leading-6 text-center whitespace-nowrap text-stone-50 max-md:flex-wrap max-md:px-14 max-md:max-w-full">
                        {categories.map((category) => (
                          <div
                            className={`hover:bg-grey-500 hover:text-red-500 border-2 border-red-400 hover:border-red-500 hover:cursor-pointer grow justify-center px-3 py-3.5 md:px-3 md:py-3.5 shadow ${category.bgColor2} rounded-[100px]`}
                            onClick={() => handleAction(category.description)}
                          >
                            {category.name}
                          </div>
                        ))}
                      </div>
                    )}
                    {/* Conditional rendering for the Buy section */}
                    {item.buyComponent && (
                      <div className="container_shadowborder-solid border-1s border-neutral-green-100 flex flex-col px-3 pb-3 text-base font-bold font-sans leading-6 bg-[#EBEBE9] rounded-[20px] max-w-[250px] m-4 shadow-md w-full">
                        <div className="flex justify-center ">
                          <img
                            src={
                              "https://images.uncommongoods.com/images/items/47600/47622_1_640px.webp"
                            }
                            alt="Transparent Image"
                            className="rounded-t-[20px] rounded-r-[00px] rounded-l-[00px] max-w-[250px] h-[150px] w-[250px] object-cover"
                            onClick={handleRedirect}
                          />
                        </div>
                        <div className="flex gap-5 justify-between px-2.5 pt-2.5 text-md font-bold font-sans text-red-300 my-2">
                          <div>
                            <span>{"39"}</span>
                            {"€"}
                          </div>
                          <LikeButton lovemeter={200}></LikeButton>
                        </div>
                        <div className="mt-2.5 text-center text-neutral-green-500 mb-3 items-center mr-4">
                          <h2 onClick={handleRedirect}>
                            {"Lovebox Spinning Heart"}
                          </h2>
                        </div>
                        <div
                          className="justify-center items-center px-16 py-2 mx-2 text-center text-red-50 whitespace-nowrap bg-red-400 leading-[150%] rounded-[100px] shadow-sm hover:bg-grey-500 hover:text-red-500 border-2 border-red-400 hover:border-red-500 hover:cursor-pointer"
                          onClick={handleRedirect}
                        >
                          Buy Gift
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mt-8 text-lg leading-7 text-neutral-grey-500 text-opacity-80">
                    {item.markTitle}:{" "}
                    <span className="text-neutral-green-300">
                      {item.description}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
