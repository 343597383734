// src/components/GeoCollector.js
import React, { useEffect } from "react";
import axios from "axios";

const GeoCollector = () => {
  useEffect(() => {
    // Fetch IP address
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        const ip = response.data.ip;
        console.log("IP Address:", ip);

        // axios
        //   .get("https://api.ipgeolocation.io/ipgeo?apiKey=5339f9519aea4f72a4a7a21024a3ca65")
        //   .then((response) => {
        //     console.log("IP Geolocation Data:", response.data);
        //   })
        //   .catch((error) =>
        //     console.error("Error fetching IP geolocation data:", error)
        //   );

        // Fetch geolocation data from ip-api.com
        // axios
        //   .get(`http://ip-api.com/json/${ip}`)
        //   .then((response) => {
        //     const geoData = response.data;
        //     console.log("Geolocation Data:", geoData);

        //     // Send the IP and geolocation data to the backend
        //     axios
        //       .post("/collect-data", {
        //         ip: ip,
        //         geolocation: geoData,
        //       })
        //       .then(() => console.log("Data sent to backend"))
        //       .catch((error) =>
        //         console.error("Error sending data to backend:", error)
        //       );
        //   })
        //   .catch((error) =>
        //     console.error("Error fetching geolocation data:", error)
        //   );
      })
      .catch((error) => console.error("Error fetching IP address:", error));
  }, []);
};

export default GeoCollector;
