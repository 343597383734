import Navbar from "../components/NavBar/NavBar2"


export default function Component() {
  return (
    <>
    <Navbar/>
    <div className="mx-auto max-w-3xl space-y-8 py-12 px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold mb-8 text-neutral-green-500">
        Terms of Service
      </h1>
      <section className="mb-8">
        <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
          Introduction
        </h2>
        <p className="mt-4 text-muted-foreground">
          Welcome to Ciqler. These Terms of Service ("Terms") govern your access
          and use of our website, mobile applications, and related services
          (collectively, the "Service"). By using the Service, you agree to be
          bound by these Terms and our Privacy Policy. If you do not agree to
          these Terms, please do not use the Service.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
          User Accounts
        </h2>
        <p className="mt-4 text-muted-foreground">
          To use the Service, you must create an account. When registering, you
          must provide accurate, current, and complete information. You are
          responsible for maintaining the confidentiality of your account
          credentials and for all activities that occur under your account. You
          agree to notify us immediately of any unauthorized use of your account
          or any other breach of security. We are not liable for any loss or
          damage arising from your failure to comply with these requirements.
          You may terminate your account at any time, and we reserve the right
          to suspend or terminate your account for any reason, including if we
          reasonably believe you have violated these Terms.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
          Use of the Service
        </h2>
        <p className="mt-4 text-muted-foreground">
          You may use the Service for personal, non-commercial purposes only.
          You agree not to use the Service for any unlawful or abusive purpose,
          including but not limited to: engaging in illegal activities, sending
          spam or unsolicited communications, or infringing on the intellectual
          property rights of others. We reserve the right to suspend or
          terminate your access to the Service if we believe you have violated
          these terms.
        </p>
        <p className="mt-4 text-muted-foreground">
          You agree to comply with all applicable laws and regulations in
          connection with your use of the Service. You must not use the Service
          in any manner that could damage, disable, overburden, or impair our
          servers or networks, or interfere with any other party's use and
          enjoyment of the Service.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
          User Content
        </h2>
        <p className="mt-4 text-muted-foreground">
          The Service may allow you to submit, upload, or post content, such as
          gift ideas, reviews, comments, or other materials ("User Content").
          You retain ownership of any User Content you submit, but by posting
          it, you grant us a worldwide, non-exclusive, royalty-free, perpetual,
          irrevocable, and fully sublicensable right to use, reproduce, modify,
          adapt, publish, translate, create derivative works from, distribute,
          perform, and display such User Content in any media. You represent and
          warrant that you own or have the necessary rights to use and authorize
          us to use all intellectual property rights in and to any User Content
          you provide. We reserve the right to monitor, review, and remove any
          User Content that we deem inappropriate, in violation of these Terms,
          or for any other reason in our sole discretion.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
          Privacy
        </h2>
        <p className="mt-4 text-muted-foreground">
          We take your privacy seriously. Our Privacy Policy, which is
          incorporated into these Terms, explains how we collect, use, and share
          your personal information. By using the Service, you consent to the
          practices described in our Privacy Policy. You acknowledge and agree
          that internet transmissions are never completely private or secure.
          You understand that any message or information you send to the Service
          may be read or intercepted by others, even if there is a special
          notice that a particular transmission is encrypted.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
          AI Recommendations
        </h2>
        <p className="mt-4 text-muted-foreground">
          Our Service uses artificial intelligence (AI) to provide personalized
          gift recommendations. While we strive to offer accurate and relevant
          suggestions, we cannot guarantee the accuracy, completeness, or
          reliability of the AI-generated recommendations. You acknowledge that
          the recommendations are based on algorithms and data and may not
          always reflect your personal preferences or the recipient's needs. You
          agree to use your own judgment when making gift selections.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
          Disclaimers and Limitations of Liability
        </h2>
        <p className="mt-4 text-muted-foreground">
          The Service is provided "as is" and without warranty of any kind. We
          disclaim all warranties, express or implied, including but not limited
          to warranties of merchantability and fitness for a particular purpose.
          We do not warrant that the Service will be uninterrupted or
          error-free, that defects will be corrected, or that the Service is
          free of viruses or other harmful components. We shall not be liable
          for any indirect, special, incidental, or consequential damages
          arising out of or related to your use of the Service, even if we have
          been advised of the possibility of such damages. Our total liability
          to you for any damages, losses, and causes of action (whether in
          contract, tort, including negligence, or otherwise) shall not exceed
          the amount paid by you, if any, for accessing the Service.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
          Indemnification
        </h2>
        <p className="mt-4 text-muted-foreground">
          You agree to indemnify, defend, and hold harmless us and our
          affiliates, officers, directors, employees, agents, and licensors from
          and against any claims, liabilities, damages, judgments, awards,
          losses, costs, expenses, or fees (including reasonable attorneys'
          fees) arising out of or relating to your violation of these Terms or
          your use of the Service, including, but not limited to, your User
          Content, any use of the Service's content, services, and products
          other than as expressly authorized in these Terms or your use of any
          information obtained from the Service.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
          Governing Law and Dispute Resolution
        </h2>
        <p className="mt-4 text-muted-foreground">
          These Terms and your use of the Service shall be governed by the laws
          of [Governing Jurisdiction], without regard to its conflict of laws
          principles. Any disputes arising out of or related to these Terms or
          the Service shall be resolved through binding arbitration in
          accordance with the rules of [Arbitration Provider]. The arbitration
          shall be conducted in [Arbitration Location], and judgment on the
          arbitration award may be entered in any court having jurisdiction
          thereof. You agree that any claim or cause of action arising out of or
          related to these Terms or the Service must be filed within one (1)
          year after such claim or cause of action arose or be forever barred.
        </p>
      </section>
      <section className="mb-8">
        <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
          Modifications
        </h2>
        <p className="mt-4 text-muted-foreground">
          We reserve the right to modify these Terms at any time. We will notify
          you of any changes by posting the updated Terms on the Service. You
          are responsible for reviewing the updated Terms. Your continued use of
          the Service after the effective date of the updated Terms constitutes
          your acceptance of the changes. If you do not agree to the updated
          Terms, you must stop using the Service.
        </p>
      </section>
      <p className="mt-4 text-muted-foreground">
        If you have any questions or concerns about these Terms, please contact
        us at
        <a className="text-red-500" href="mailto:Hello@ciqler.com">
          {" "}
          Hello@ciqler.com
        </a>
        .
      </p>
    </div>
    </>
  );
}
