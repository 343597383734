import React from "react";
import { useState, useRef, useEffect } from "react";

import "../Styles/Profile3.scss";
import Calender from "./CalenderTail.jsx";
import Navbar from "../NavBar/NavBar.jsx";
import PopUpCalender from "./Calender2.jsx";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MiniNav from "./MiniNav.jsx";
import ProfileComponent from "./ProfileComponent.jsx";
import Footer from "../Footer/Footer.jsx";
import axios from "axios";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo/index.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import "../Styles/Calender2.scss";
import PopUp from "./SignInUpPopUp.jsx";
import moment from "moment";

// import { json } from "stream/consumers";

export default function ProfilePage() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [newSpecialDays, setNewSpecialDays] = useState(false);
  const myRef = useRef(null);
  const email = localStorage.getItem("email");
  const [eventDateChange, setEventDateChange] = useState(dayjs("YYYY-MM-DD"));
  const [eventName, setEventName] = useState("");
  const [eventDesc, setEventDesc] = useState("");
  const [dataResp, setDataResp] = useState("");
  const [isSignInPopupVisible, setIsSignInPopupVisible] = useState(false);

  const handleSignInPopupOpen = () => {
    setIsPopupVisible(true);
  };

  const handleSignInPopupClose = () => {
    setIsPopupVisible(false);
  };

  const regex =
    "^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00)[-/]?02[-/]?29)";
  // const [stringEventDate, setStringEventDate] = useState("");
  // const stringEventDate = eventDateChange.format("MM/DD/YYYY");
  const eventDate = JSON.stringify(eventDateChange).replace(/"|'/g, "");

  const event = {
    eventDate,
    eventName,
    eventDesc,
  };

  const handleDateChange = (newValue) => {
    setEventDateChange(newValue);
  };

  useEffect(() => {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handlePopupOpen = () => {
    setIsPopupVisible(true);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  const addNewEvent = async () => {
    // console.log(email, password);
    const response = await fetch(
      "https://gzd1kcywwk.execute-api.us-east-1.amazonaws.com/api/user/event",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ email: email, event: event }),
      }
    );
    const res = await response;
    if (res.status === 403) {
      // Clear email and password
      // setemail("");
      // setPassword("");
      // Prompt user to enter credentials again
      console.error("Error:", res);
    }
  };

  const handleClick = () => {
    if (clicked) {
      // Do something different on the second click
      // setStringEventDate(
      //   JSON.stringify(eventDateChange).replace(/"|'/g, "").match(regex)[1]
      // );
      // setStringEventDate(eventDateChange);
      console.log(event);
      addNewEvent();
      getResponse();
    } else {
      // Do something on the first click
      setIsPopupVisible(true);
      setNewSpecialDays(9 - 9 - 9);
    }
    setClicked(true);
  };

  async function getResponse() {
    try {
      const response = await fetch(
        "https://gzd1kcywwk.execute-api.us-east-1.amazonaws.com/api/user/profile",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        }
      );
      console.log(response.status);
      if (response.status === 403) {
        setIsSignInPopupVisible(true);
        localStorage.setItem("loggedin", JSON.stringify(false));
      }
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // setDataResp(data.events.map((event) => event.eventDateChange));
      setDataResp(data);

      // console.log(data.events);
    } catch (error) {
      console.log("ERROR:", { error });
    }
  }

  useEffect(() => {
    getResponse();
  }, []);

  // console.log(dataResp);
  // const events = dataResp.map((event) => event.eventDateChange);
  // console.log(events);
  // const arrayData = dataResp.map((item) => [
  //   item.eventName,
  //   item.eventDateChange,
  //   item.eventDesc,
  // ]);

  // console.log(dataResp);

  return (
    <div>
      <Navbar />

      <div className="main-component-div">
        <div>
          {isSignInPopupVisible && <PopUp onClose={handlePopupClose} />}
        </div>

        <div className="mini-nav-profile" ref={myRef}></div>
        <MiniNav />
        <div className="component-profile">
          <div className="info-profile"></div>
          <ProfileComponent response={dataResp} />
          <div className="Calender-profile profile block">
            <div>
              {" "}
              <h1 className="titular">
                <b>Event Calender</b>
              </h1>
            </div>
            <div className="calender-div-profile-mini">
              {dataResp && <Calender response={dataResp} />}
            </div>
          </div>

          {/* CALENDAR DAY (RIGHT-CONTAINER) */}
          <div className="block profile Calender-profile">
            <div className="add-event-div">
              {isPopupVisible && (
                <div>
                  {" "}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateField"]}>
                      <DateField
                        // disablePast
                        minDate={dayjs.js}
                        label="Date of Event"
                        value={eventDateChange}
                        onChange={(newValue) => setEventDateChange(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              )}
              {isPopupVisible && (
                <TextField
                  id="outlined-basic"
                  label="Event"
                  variant="outlined"
                  onChange={(e) => setEventName(e.target.value)}
                />
              )}
              {isPopupVisible && (
                <TextField
                  id="outlined-basic"
                  label="Event Details"
                  variant="outlined"
                  onChange={(e) => setEventDesc(e.target.value)}
                />
              )}
            </div>
            <div className="arrow-btn-container"></div>
            <a className="add-event button" href="#27" onClick={handleClick}>
              ADD EVENT
            </a>
          </div>
          {/* CALENDAR DAY (RIGHT-CONTAINER) */}
          <div />
        </div>
      </div>
      <Footer />
    </div>
  );
}
