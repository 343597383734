import { React, useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
// import "./genderpage.css";
// import "./Home.scss"
import logo from "../Assets/Images/GiftsLogo.png";
import NavBar from "../components/NavBar/NavBar2.jsx";
import Body from "../components/HomePage/Body.jsx";
import Footer from "../components/Footer/Footer.jsx";
import IPGeoHandler from "../Services/IPGeoHandler.jsx";

const HerProducts = () => {
  // const [keywordsgifts, setkeywordsGifts] = useState([]);
  // const [piigifts, setpiiGifts] = useState([]);
  // const [gifts, setGifts] = useState([]);
  // const [price, setPrice] = useState(3000);
  // const [loveMeter, setloveMeter] = useState(0);
  // const [textfield, setTextfield] = useState("");
  // const [isLoading, setIsLoading] = useState(false);

  // const handleInput = (e) => {
  //   setPrice(e.target.value);
  // };

  // const handleLoveMeter = (e) => {
  //   setloveMeter(e.target.value);
  // };

  // useEffect(() => {}, []);

  // const getGifts = async () => {
  //   const params = {
  //     attribute: "gender",
  //     value: "female",
  //   };

  //   const response = await fetch(
  //     "https://gzd1kcywwk.execute-api.us-east-1.amazonaws.com/api/gifts/filter",
  //     {
  //       method: "POST",
  //       body: JSON.stringify(params),
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   ).catch((error) => {
  //     console.log("found error", error);
  //   });
  //   setIsLoading(false);

  //   setGifts(await response.json());
  //   // window.location.reload(true);
  // };

  // const getWantedGifts = async (e) => {
  //   // setTextfield(inputRef.current.value);
  //   const params = {
  //     text: textfield,
  //   };
  //   setIsLoading(true);

  //   const [keyphrasesResponse, piiResponse, toknizeResponse, wordnetResponse] =
  //     await Promise.all([
  //       fetch(
  //         "https://gzd1kcywwk.execute-api.us-east-1.amazonaws.com/api/nl/keyphrases",
  //         {
  //           method: "POST",
  //           body: JSON.stringify(params),
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       ).catch((error) => {
  //         console.log("found error", error);
  //       }),
  //       fetch(
  //         "https://gzd1kcywwk.execute-api.us-east-1.amazonaws.com/api/nl/pii",
  //         {
  //           method: "POST",
  //           body: JSON.stringify(params),
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       ).catch((error) => {
  //         console.log("found error", error);
  //       }),
  //       fetch(
  //         "https://gzd1kcywwk.execute-api.us-east-1.amazonaws.com/api/nlp/tokenize",
  //         {
  //           method: "POST",
  //           body: JSON.stringify(params),
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       ).catch((error) => {
  //         console.log("found error", error);
  //       }),
  //       fetch(
  //         "https://gzd1kcywwk.execute-api.us-east-1.amazonaws.com/api/nlp/wordnet",
  //         {
  //           method: "POST",
  //           body: JSON.stringify(params),
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       ).catch((error) => {
  //         console.log("found error", error);
  //       }),
  //     ]);
  //   setIsLoading(false);

  //   var wantedGifts = await keyphrasesResponse.json();
  //   var ageGifts = await piiResponse.json();
  //   var tokinzeGifts = await toknizeResponse.json();
  //   var wordnetGifts = await wordnetResponse.json();
  //   var unrankedGifts = ageGifts
  //     .concat(wantedGifts)
  //     .concat(tokinzeGifts)
  //     .concat(wordnetGifts);
  //   const uniqueElements = unrankedGifts.filter(
  //     (value, index, self) =>
  //       index === self.findIndex((t) => t._id.valueOf() === value._id.valueOf())
  //   );
  //   const rankedGifts = uniqueElements.map((element) => {
  //     return {
  //       ...element,
  //       rank: unrankedGifts.filter(
  //         (gift) => gift._id.valueOf() === element._id.valueOf()
  //       ).length,
  //     };
  //   });
  //   rankedGifts.sort((g1, g2) =>
  //     g1.rank < g2.rank ? 1 : g1.rank > g2.rank ? -1 : 0
  //   );
  //   setGifts(rankedGifts);
  // };

  return (
    <div>
      <NavBar />
      <IPGeoHandler />

      <Body />
      <Footer />
    </div>
  );
};

export default HerProducts;
