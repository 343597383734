import { React, useState, useEffect } from "react";

import Navbar from "../components/NavBar/NavBar2.jsx";
import Search from "../components/SearchArea/Search.jsx";
import Filters from "../components/Filters/Filterz.jsx";
import useAppStore from "../Store/Store.jsx";
import ScrollToTop from "../components/Scroll_to_top/Scroll_to_top.jsx";

import "../components/Styles/SearchPage.scss";
import Loader from "../components/Loader/Loader.jsx";

import { MentionProvider } from "../components/SearchArea/MentionContext.jsx";
import RatingFaces from "../components/Rating/Rating.jsx";

const SearchPage = () => {
  const { gifts, isSearching } = useAppStore((state) => ({
    gifts: state.gifts,
    isSearching: state.isSearching,
  }));

  return (
    <MentionProvider>
      <div className="bg-grey-500">
        <Navbar />

        {isSearching ? (
          <div className="flex justify-center items-center h-screen">
            <ScrollToTop />
            <Loader />
          </div>
        ) : (
          <>
            {/* <RatingFaces /> */}
            <Filters gifts={gifts} />
          </>
        )}
        <Search pageType="searchPage" />
      </div>
    </MentionProvider>
  );
};

export default SearchPage;
