import Navbar from "../components/NavBar/NavBar2";

export default function Component() {
  return (
    <>
      <Navbar />
      <div className="mx-auto max-w-3xl space-y-8 py-12 px-4 sm:px-6 lg:px-8">
        <div>
          <h1 className="text-3xl font-bold tracking-tight text-neutral-green-500">
            Privacy Policy
          </h1>
          <p className="mt-4 text-muted-foreground">
            Effective Date: July 6, 2024
          </p>
        </div>
        <div className="space-y-6">
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
              Information We Collect
            </h2>
            <p className="mt-4 text-muted-foreground">
              We collect certain information from you when you use our website
              or application, including your name, email address, phone number,
              and any other information you provide to us. We may also collect
              information about your device, such as your IP address, browser
              type, and location data, as well as information about your usage
              of our services, such as the pages you visit and the actions you
              take.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
              How We Use Your Information
            </h2>
            <p className="mt-4 text-muted-foreground">
              We use the information we collect to provide and improve our
              services, to communicate with you, and to comply with legal
              requirements. We may also use your information for marketing
              purposes, such as sending you newsletters or promotional
              materials, but we will always give you the option to opt-out of
              such communications. We may also use your information to
              personalize your experience on our platform, such as by
              recommending products or services based on your past interactions.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
              How We Protect Your Information
            </h2>
            <p className="mt-4 text-muted-foreground">
              We take the security of your information seriously and have
              implemented various measures to protect it, including encryption,
              access controls, and regular security audits. We also have
              policies and procedures in place to ensure that your information
              is only accessed by authorized personnel and is not misused or
              disclosed without your consent.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
              Your Rights
            </h2>
            <p className="mt-4 text-muted-foreground">
              You have certain rights regarding your personal information,
              including the right to access, correct, or delete your
              information. You also have the right to opt-out of certain
              marketing communications and to request that we limit the use of
              your information. If you have any questions or concerns about your
              rights, please contact us at privacy@example.com. We will respond
              to your request within 30 days and will provide you with a
              detailed explanation of how we have addressed your concerns.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
              Changes to This Policy
            </h2>
            <p className="mt-4 text-muted-foreground">
              We may update this privacy policy from time to time to reflect
              changes in our practices or applicable laws. We will notify you of
              any material changes by posting the updated policy on our website
              or by sending you an email at least 30 days in advance of the
              changes taking effect. We encourage you to review this policy
              periodically to stay informed about how we are protecting your
              information.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold tracking-tight text-neutral-green-500">
              Contact Us
            </h2>
            <p className="mt-4 text-muted-foreground">
              If you have any questions or concerns about this privacy policy or
              our data practices, please contact us at privacy@example.com. We
              will respond to your inquiry within 5 business days and will work
              with you to address your concerns.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
