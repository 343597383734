import { React, useState, useContext } from "react";
import NavBar from "../components/NavBar/NavBar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "../components/Styles/about.scss";
import Accordion from "react-bootstrap/Accordion";

const About = () => {
  return (
    <div>
      <div>
        <NavBar></NavBar>
      </div>
      <div className="body">
        <div className="about-div mainDiv">
          <div className="gatherabout">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Our Goal</Accordion.Header>
                <Accordion.Body>
                  <ul className="goal">
                    <li>
                      {" "}
                      We are on a mission to celebrate with the world their
                      happy times. We will find and tailor for you the best gift
                      ideas that you can give and share with your loved ones.
                    </li>
                    <li>
                      {" "}
                      Our AI-powered gift recommendation engine takes the
                      guesswork out of gift-giving by suggesting personalized
                      gift ideas based on the recipient's interests and
                      preferences, your budget, type of event, degree of
                      relationship, and other people’s personalities, traits.
                      All you need to do is to express your thoughts.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>How it works</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <ul>
                      <li>
                        Ciqler takes your text, and cuts it into small pieces
                        called tokens. Every token gets mapped into its root
                        meaning and sentimental synonyms.
                      </li>
                      <li>
                        Ciqler then searches the web to find for you the perfect
                        ideas. choosing the gift with an empathetic annotation.
                      </li>
                      <li>
                        {" "}
                        By that it helps you pick the perfect gift for the
                        occassion and the perfect gift they want.
                      </li>
                    </ul>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Examples</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      It could be dining out at a fancy restaurant with your
                      mother to thank her for everything.
                    </li>
                    <li>
                      It could be a letter to your Grandson to tell him that you
                      miss him.
                    </li>
                    <li>
                      It could be “The Best Boss Mug” gift for your Manager at
                      her/his Farewell.
                    </li>
                    <li>
                      It could be a hundards ideas for all the people in your
                      life.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>The Team</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <a href="https://www.linkedin.com/in/ziad-hossam-713113144/">
                      <b>
                        <u> Ziad Aly</u>
                      </b>
                    </a>
                    {/* <br> </br> */}
                    <a href="https://www.linkedin.com/in/yasser-mohammed-aljibaly/">
                      <b>
                        <u>Yasser Mohamed</u>
                      </b>
                    </a>
                    <a href="https://www.linkedin.com/in/eslam-ahmed-249730194/">
                      <b>
                        <u>Eslam Ahmed</u>
                      </b>
                    </a>
                    <a href="https://www.linkedin.com/in/mariam-barakat/">
                      <b>
                        <u>Maraim Barakat</u>
                      </b>
                    </a>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Join The Team</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    If you feel that you can contribute to our platform feel
                    free to send your CV to{" "}
                    <a href="mailto: Hello@gifts-bot.com">
                      <u> Hello@Ciqler.com</u>
                    </a>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="About-Footer-div">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default About;
