import React, { useState, useEffect } from "react";
import "../Styles/NavBar.scss";
import dropdown from "../../Assets/Images/dropdown.png";
import xbutton from "../../Assets/Images/xbutton.png";

function DropdownMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [loggedIn, setLoggedin] = useState(false);

  useEffect(() => {
    const handleLoggedin = () => {
      const loggedIn = JSON.parse(localStorage.getItem("loggedin"));
      if (loggedIn) {
        setLoggedin(true);
      }
    };
    handleLoggedin();
  }, []);
  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
    console.log(isOpen);
  };

  return (
    <div>
      <img
        id="mobile-cta"
        loading="lazy"
        src={dropdown}
        alt="Logo"
        onClick={handleDropdownClick}
        className="my-auto w-6 aspect-square hover:cursor-pointer"
      />

      {isOpen && (
        <div>
          <nav className="bg-red-500 h-auto">
            <div className="float-right mx-4 my-4">
              <img
                id="mobile-cta"
                className="image-175"
                src={xbutton}
                alt="Logo"
                onClick={handleDropdownClick}
              />
            </div>
            <div className="my-5">
              {loggedIn && (
                <div className="profile-picture small-profile-picture dropdown-profile mb-4 ml-12">
                  <a href="/profile">
                    <img
                      width="40px"
                      alt="Anne Hathaway picture"
                      src="https://img.freepik.com/premium-vector/female-robot-avatar-facing-half-side-with-long-shadow_100659-286.jpg?w=2000"
                    />
                  </a>
                </div>
              )}
              <ul className="dropdown-menu ">
                <li>
                  <a href="/about" className="text-grey-500 text-3xl my-3 py-2">
                    {" "}
                    About{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/Integratewithus"
                    className="text-grey-500 text-3xl my-3 py-2"
                  >
                    {" "}
                    Join Us{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="/Search"
                    className="text-grey-500 text-3xl my-3 py-2"
                  >
                    {" "}
                    Try It{" "}
                  </a>
                </li>
                {!loggedIn && (
                  <li className="go-premium-cta bg-white mt-3 py-2 mx-0 text-xl	">
                    <a href="/login" className="">
                      <span className=" text-custom-sweetBrown">Login/</span>
                      <span className=" text-custom-newYorkPink">SignUp</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      )}
    </div>
  );
}

export default DropdownMenu;
