import { React, useEffect } from "react";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import HomePage from "./Pages/Home.jsx";
import SearchPage from "./Pages/SearchPage.jsx";
import IntegrateWithUs from "./components/IntegrateWithUs/IntegrateWithUs.jsx";
import TagManager from "react-gtm-module";
import About from "./Pages/about.jsx";
import Calender from "./components/Profile/CalenderPage.jsx";
import Login from "./Pages/Login.jsx";
import Profile from "./components/Profile/Profile2.jsx";
import Profile3 from "./components/Profile/Profile4.jsx";
import Calendertail from "./components/Profile/CalenderTail.jsx";
import ScrollToTop from "./components/Scroll_to_top/Scroll_to_top.jsx";
import TipTapMention from "./components/SearchArea/TipTapMention.jsx";
import ResetPassword from "./components/Authentication/ResetPassword.jsx";
import ForgetPassword from "./components/Authentication/ForgetPassword.jsx";
import SignUp from "./components/Authentication/SignUp.jsx";
import PrivacyPolicy from "./Pages/PrivacyPolicy.jsx";
import TermsOfService from "./Pages/TermsOfService.jsx";

const App = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-YT4TGNYZ90";
    script.async = true;
    document.head.appendChild(script);

    const inlineScript = document.createElement("script");
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-YT4TGNYZ90');
    `;
    document.head.appendChild(inlineScript);
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route path={"/"} exact component={HomePage} />
        <Route path={"/search"} exact component={SearchPage} />
        <Route path={"/about"} exact component={About} />
        <Route path={"/Integratewithus"} exact component={IntegrateWithUs} />
        <Route path={"/calender"} exact component={Calendertail} />
        <Route path={"/login"} exact component={Login} />
        <Route path={"/profile"} exact component={Profile3} />
        <Route path={"/tiptap"} exact component={TipTapMention} />
        <Route path={"/reset-password"} exact component={ResetPassword} />
        <Route path={"/forget-password"} exact component={ForgetPassword} />
        <Route path={"/policy"} exact component={PrivacyPolicy} />
        <Route path={"/terms"} exact component={TermsOfService} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
