import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  validatePassword,
  validateConfirmPassword,
} from "../../Utils/Validation.jsx";
import { BASE_URL } from "../../Global.js";

function ResetPasswordForm() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const { token } = useParams(); // Access the token from URL params

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get the current URL
    const currentUrl = window.location.href;

    // Use URLSearchParams to get query parameters
    const urlParams = new URLSearchParams(currentUrl.split("?")[1]);

    // Fetch the token from query parameters
    const token = urlParams.get("token");
    const passwordValidationError = validatePassword(password);
    const confirmPasswordValidationError = validateConfirmPassword(
      password,
      confirmPassword
    );

    if (passwordValidationError || confirmPasswordValidationError) {
      setPasswordError(passwordValidationError);
      setConfirmPasswordError(confirmPasswordValidationError);
      setMessage("Please fix the errors above.");
    } else {
      setPasswordError("");
      setConfirmPasswordError("");
      setMessage("");

      if (password !== confirmPassword) {
        setMessage("Passwords do not match");
        return;
      }

      try {
        // Fetch the token from URL params (already fetched in the beginning)
        const response = await axios.post(
          `${BASE_URL}/api/user/reset-password`,
          { password },
          { params: { token: token } }
        );
        setMessage(response.data.message);
        setPassword("");
        setConfirmPassword("");
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      } catch (error) {
        setMessage(error.response.data.error);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="mx-auto max-w-md p-8 bg-white rounded shadow-lg">
        <div className="text-2xl mb-4">Reset Password</div>
        <div className="mb-6">
          Enter a new password and confirm it to reset your account password.
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="password">New Password</label>
            <input
              id="password"
              type="password"
              placeholder="Enter a new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="border border-gray-300 rounded px-3 py-2 w-full"
            />
            {passwordError && (
              <p className="text-red-500 text-sm">{passwordError}</p>
            )}
          </div>
          <div className="space-y-2">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              id="confirmPassword"
              type="password"
              placeholder="Confirm your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="border border-gray-300 rounded px-3 py-2 w-full"
            />
            {confirmPasswordError && (
              <p className="text-red-500 text-sm">{confirmPasswordError}</p>
            )}
          </div>
          <button
            type="submit"
            className="bg-red-600 hover:bg-red-700 text-white rounded px-4 py-2 w-full"
          >
            Reset Password
          </button>
          {message && <p className="mt-4 text-green-500">{message}</p>}
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
